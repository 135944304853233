import {errorCatch, errorCheck} from '../lib/actionErrorHandler'

import {alphanumSanitize} from '../lib/stringValidation'
import axios from '../lib/axios'

export function selectGrower(grower = {id: -1}) {
    return {
        type: 'SELECT_GROWER',
        grower,
    }
}

export function addGrower(grower) {
    return dispatch => {
        grower.postalCode = alphanumSanitize(grower.postalCode)

        return axios.post('/jobs/api/growers/', grower)
            .then(response => response.data)
            .then(savedGrower => dispatch(selectGrower(savedGrower)))
            .then(() => dispatch(fetchGrowers()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function updateGrower(grower) {
    return dispatch => {
        grower.postalCode = alphanumSanitize(grower.postalCode)

        return axios.put(`/jobs/api/growers/${grower.id}`, grower)
            .then(response => response.data)
            .then(() => dispatch(fetchGrowers()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function deleteGrower(growerId) {
    return dispatch => {
        return axios.delete(`/jobs/api/growers/${growerId}`)
            .then(() => dispatch(fetchGrowers()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function fetchGrowers() {
    return dispatch => {
        dispatch(requestGrowers())

        return axios.get('/jobs/api/growers?pageSize=500&page=1')
            .then(response => {
                dispatch(receiveGrowers(response.data.data))

                return response.data.pagination.next
            })
            .then(next => paginateGrowers(next, dispatch))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveGrowers([]))
            })
    }
}

function paginateGrowers(next, dispatch) {
    if (next) {
        return axios.get(`/jobs/api/growers?cursor=${next}`)
            .then(response => {
                dispatch(appendGrowers(response.data.data))

                return response.data.pagination.next
            })
            .then(next => paginateGrowers(next, dispatch))
    }

    return dispatch({type: 'DONE_FETCHING_GROWERS'})
}

function requestGrowers() {
    return {
        type: 'REQUEST_GROWERS',
    }
}

function receiveGrowers(growers) {
    if (!Array.isArray(growers)) { growers = [] }

    return {
        type: 'RECEIVE_GROWERS',
        growers: growers,
    }
}

function appendGrowers(growers) {
    if (!Array.isArray(growers)) { growers = [] }

    return {
        type: 'APPEND_GROWERS',
        growers: growers,
    }
}
