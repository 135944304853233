/* eslint-disable no-nested-ternary */
import './reportStyles.css'

import { Box, Stack } from '@atd/rui-base.mui.all'
import {DEFAULT_DISPLAY_VALUE, METRIC} from './applicationReportConstants'

import {BlueDivider} from '@atd/one-slingshot.report.blue-divider'
import { DisplayValue } from '@atd/one-slingshot.report.display-value'
import {Divider} from '@atd/rui-base.mui.divider'
import React from 'react'
import {ReportHeading} from '@atd/one-slingshot.report.report-heading'
import {ReportSectionChild} from '@atd/one-slingshot.report.report-section-child'
import {ReportSectionParentFlex} from '@atd/one-slingshot.report.report-section-parent-flex'
import {ReportTable} from '@atd/one-slingshot.report.report-table'
import {RuiRatelegend} from '@atd/rui.components.rui-ratelegend'
import {RuiRatemap} from '@atd/rui.components.rui-ratemap'
import { useDesignTheme } from '@atd/design.theme-context'

export default function FieldAnalyzerReportPage({allPageData, uomPreference, hideNullValues}) {
    function shouldShowRow(value) {
        return (!!value) && (value !== DEFAULT_DISPLAY_VALUE || !hideNullValues)
    }

    return (
        <div className="report-page">
            <TableWrapper>
                {allPageData.map((pageData, index) => (
                    <div key={index} className="page-break-after">
                        <ReportHeading
                            name={pageData.headerInfo.name}
                            addressLine1={pageData.headerInfo.addressLine1}
                            addressLine2={pageData.headerInfo.addressLine2}
                            addressLine3={pageData.headerInfo.addressLine3}
                            phone={pageData.headerInfo.phone}
                            email={pageData.headerInfo.email}
                            reportTitle="Application Report"
                            reportSubTitle={pageData.headerInfo.subtitle}
                            workorderDetails={pageData.headerInfo.workorder} />
                        <BlueDivider />
                        <ReportTable shadeRows>
                            <ReportTable.Head sx={{backgroundColor: 'inherit'}}>
                                <ReportTable.Row>
                                    {pageData.subheaderInfo.map((header, i) => {
                                        if (header.item) {
                                            return (
                                                <ReportTable.Cell
                                                    colSpan={header.colSpan ? header.colSpan : 1}
                                                    key={'subHeader_Title' + i}
                                                    sx={{
                                                        color: 'black',
                                                        textAlign: 'center',
                                                        fontWeight: 'normal',
                                                    }}>{header.item}:</ReportTable.Cell>
                                            )
                                        }

                                        return null
                                    })}
                                </ReportTable.Row>
                            </ReportTable.Head>
                            <ReportTable.Body>
                                <ReportTable.Row sx={{ backgroundColor: 'inherit!important' }}>
                                    {pageData.subheaderInfo.map((header, i) => {
                                        return (
                                            <ReportTable.Cell key={'subHeader_Value_' + i} sx={{color: 'black', textAlign: header.textAlign ? header.textAlign : 'center', fontWeight: 'bold', fontSize: '1.25rem', maxWidth: '150px'}}>
                                                <DisplayValue
                                                    value={header.value}
                                                    isUOM={header.isUOM}
                                                    metricUnits={header.metricUnits}
                                                    imperialUnits={header.imperialUnits}
                                                    baseUnits={header.baseUnits}
                                                    uomPreference={uomPreference}
                                                    removeUnitsIfDefault={header.removeUnitsIfDefault}
                                                    precision={header.precision}
                                                    preserveStringCase={header.preserveStringCase}
                                                    defaultDisplayValue={header.defaultDisplayValue}
                                                    allowTruncate={header.allowTruncate}
                                                />
                                            </ReportTable.Cell>
                                        )
                                    })}
                                </ReportTable.Row>
                            </ReportTable.Body>
                        </ReportTable>
                        <ReportSectionParentFlex title="Field Information">
                            <div className="report-field-info-left">
                                <div className="report-flex-wrapper">
                                    <div className="report-flex-2">
                                        {shouldShowRow(pageData.fieldInformation.customer) && <ReportSectionChild
                                            sectionData={[
                                                { item: 'Customer', value: pageData.fieldInformation.customer },
                                            ]}
                                            uomPreference={uomPreference}
                                            largeValue={true} />}
                                    </div>
                                </div>
                                <div className="report-flex-wrapper">
                                    <div className="report-flex-2">

                                        <div className="report-flex-wrapper">
                                            {shouldShowRow(pageData.fieldInformation.office) && <div className="report-flex-1">
                                                <ReportSectionChild
                                                    sectionData={[
                                                        { item: 'Office', value: pageData.fieldInformation.office },
                                                    ]}
                                                    uomPreference={uomPreference} />
                                            </div>}
                                            {shouldShowRow(pageData.fieldInformation.address) && <div className="report-flex-2">
                                                <ReportSectionChild
                                                    sectionData={[
                                                        { item: 'Address', value: pageData.fieldInformation.address },
                                                    ]}
                                                    uomPreference={uomPreference} />
                                            </div>}
                                        </div>
                                        <div className="report-flex-wrapper">
                                            {shouldShowRow(pageData.fieldInformation.cell) && <div className="report-flex-1">
                                                <ReportSectionChild
                                                    sectionData={[
                                                        { item: 'Cell', value: pageData.fieldInformation.cell },
                                                    ]}
                                                    uomPreference={uomPreference} />
                                            </div>}
                                            {shouldShowRow(pageData.fieldInformation.email) && <div className="report-flex-2">
                                                <ReportSectionChild
                                                    sectionData={[
                                                        { item: 'Email', value: pageData.fieldInformation.email },
                                                    ]}
                                                    uomPreference={uomPreference} />
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <Divider style={{ margin: 5 }} />

                                <div className="report-flex-wrapper">
                                    <div className="report-flex-2 report-farm-info">
                                        <div className="report-flex-wrapper">
                                            {shouldShowRow(pageData.fieldInformation.farm) && <div className="report-flex-1">
                                                <ReportSectionChild
                                                    sectionData={[
                                                        { item: 'Farm', value: pageData.fieldInformation.farm },
                                                    ]}
                                                    uomPreference={uomPreference} />
                                            </div>}
                                            {shouldShowRow(pageData.fieldInformation.field) && <div className="report-flex-1">
                                                <ReportSectionChild
                                                    sectionData={[
                                                        { item: 'Field', value: pageData.fieldInformation.field },
                                                    ]}
                                                    uomPreference={uomPreference} />
                                            </div>}
                                        </div>
                                        <div className="report-flex-wrapper">
                                            {shouldShowRow(pageData.fieldInformation.township) && <div className="report-flex-1">
                                                <ReportSectionChild
                                                    sectionData={[
                                                        { item: 'Township', value: pageData.fieldInformation.township },
                                                    ]}
                                                    uomPreference={uomPreference} />
                                            </div>}
                                            {shouldShowRow(pageData.fieldInformation.section) && <div className="report-flex-1">
                                                <ReportSectionChild
                                                    sectionData={[
                                                        { item: 'Section', value: pageData.fieldInformation.section },
                                                    ]}
                                                    uomPreference={uomPreference} />
                                            </div>}
                                        </div>
                                        <div className="report-flex-wrapper">
                                            {shouldShowRow(pageData.fieldInformation.countyState) && <div className="report-flex-1">
                                                <ReportSectionChild
                                                    sectionData={[
                                                        { item: 'County/State', value: pageData.fieldInformation.countyState },
                                                    ]}
                                                    uomPreference={uomPreference} />
                                            </div>}
                                            <div className="report-flex-1"></div>
                                        </div>
                                    </div>
                                    <div className="report-flex-1 report-legend">
                                        {(pageData.mapData.features && pageData.mapData.features.features.length > 0) && (
                                            <RuiRatelegend
                                                data={pageData.mapData.features}
                                                theme="primary"
                                                calculatedUnitProps={{
                                                    uomPreference,
                                                    liquid: pageData.mapData.isLiquid,
                                                }}
                                                rateProperty="actualRate"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {(pageData.mapData.features && pageData.mapData.features.features.length) ? (
                                <div className="report-map">
                                    <RuiRatemap
                                        data={pageData.mapData.features}
                                        mapboxAccessToken="pk.eyJ1Ijoic2xpbmdzaG90IiwiYSI6IndqUS1ELVUifQ.saxKOBK4ETcj29XHe_eqrA"
                                        scaleControl={uomPreference == METRIC ? 'metric' : 'imperial'}
                                        navigationControl={true}
                                        rateProperty="actualRate"
                                    />
                                </div>
                            ) : pageData.mapData.imageData ? <img width="650" src={'data:image/png;base64,' + pageData.mapData.imageData} /> : (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        color: '#555',
                                        fontSize: '0.875rem',
                                    }}
                                >No coverage data found for this product</div>
                            )}
                        </ReportSectionParentFlex>
                        <div className="report-application-detail-large">
                            {pageData.applicationDetail.columnNames.map((_, i) => (
                                <ApplicationDetail
                                    applicationDetail={pageData.applicationDetail}
                                    uomPreference={uomPreference}
                                    hideNullValues={hideNullValues}
                                    key={i}
                                    index={i}
                                />
                            ))}
                        </div>
                        <div className="report-application-detail-small">
                            {pageData.applicationDetail.columnNames.map((_, i) => (
                                <ApplicationDetail
                                    applicationDetail={pageData.applicationDetailSmallScreen}
                                    uomPreference={uomPreference}
                                    hideNullValues={hideNullValues}
                                    key={i}
                                    index={i}
                                />
                            ))}
                        </div>
                        <ProductsTable
                            uomPreference={uomPreference}
                            ingredients={pageData.ingredientInfo.ingredientRows}
                            product={pageData.productInfo}
                            hideNullValues={hideNullValues}
                        />
                    </div>
                ))}
            </TableWrapper>
        </div>
    )
}


function ApplicationDetail({ applicationDetail, uomPreference, hideNullValues, index }) {

    const rowData = [
        { rowName: 'Equipment', values: applicationDetail.equipment[index] },
        { rowName: 'Completed Area', values: applicationDetail.completedArea[index], isUOM: true },
        { rowName: 'Completion Date', values: applicationDetail.completionDate[index] },
        { rowName: 'Start Time', values: applicationDetail.startTime[index] },
        { rowName: 'Stop Time', values: applicationDetail.stopTime[index] },
        { rowName: 'Operator', values: applicationDetail.operator[index] },
        { rowName: 'License #', values: applicationDetail.license[index] },
        // eslint-disable-next-line multiline-ternary
        applicationDetail.totalWeight[index][0].value > 0
            // eslint-disable-next-line multiline-ternary
            ? { rowName: 'Total Weight', values: applicationDetail.totalWeight[index], isUOM: true }
            : { rowName: 'Total Volume', values: applicationDetail.totalVolume[index], isUOM: true },
        { rowName: 'Ground Speed', values: applicationDetail.groundSpeed[index], isUOM: true },
        { rowName: 'Default Target Rate', values: applicationDetail.defaultRate[index] },
        { rowName: 'Minimum Target Rate', values: applicationDetail.minimumRate[index] },
        { rowName: 'Maximum Target Rate', values: applicationDetail.maximumRate[index] },
    ].concat(applicationDetail.isLiquid ? [
        { rowName: 'Boom Height', values: applicationDetail.boomHeight[index], isUOM: true },
        { rowName: 'Tip Type / Tip Size', values: applicationDetail.tipTypeTipSize[index] },
        { rowName: 'Tip Pressure', values: applicationDetail.tipPressure[index], isUOM: true },
        { rowName: 'Field Conditions', values: applicationDetail.fieldConditions[index] },
    ] : [{ rowName: 'Field Conditions', values: applicationDetail.fieldConditions[index] }])

    function shouldShowSection(values) {
        if (!hideNullValues || values.some(v => v.alwaysShow)) return true

        return values.some(v => v.value &&
            v.value !== DEFAULT_DISPLAY_VALUE &&
            v.value !== `${DEFAULT_DISPLAY_VALUE} / ${DEFAULT_DISPLAY_VALUE}` &&
            v.value != 0)
    }

    return (
        <ReportTable sx={{ tableLayout: 'fixed' }} shadeRows>
            <colgroup>
                {/*
                    "float" the columns to the left, but make sure the last column
                    has a min-width of 125px (min-width doesn't have an effect on <col />)
                */}
                {applicationDetail.columnNames[index].map((_, i) => (
                    <col key={i} style={{ width: '16.5%' }} />
                ))}
                <col />
                <col style={{ width: '125px' }} />
            </colgroup>
            <ReportTable.Head>
                <ReportTable.Row>
                    <ReportTable.Cell>{applicationDetail.tableTitle}</ReportTable.Cell>
                    {applicationDetail.columnNames[index].map((name, i) => (
                        <ReportTable.Cell
                            key={i}
                            colSpan={i === applicationDetail.columnNames[index].length - 1 ? 2 : 1}
                        >
                            {name}
                        </ReportTable.Cell>
                    ))}
                </ReportTable.Row>
            </ReportTable.Head>
            <ReportTable.Body>
                {rowData.map(({ rowName, values, isUOM }, i) => {
                    if (shouldShowSection(values)) {
                        return <ReportTable.Row key={i}>
                            <ReportTable.Cell sx={{ fontWeight: 'fontWeightBold' }}>{rowName}</ReportTable.Cell>
                            {values.map((display, i) => (
                                <ReportTable.Cell key={i} colSpan={i === values.length - 1 ? 2 : 1}>
                                    <DisplayValue
                                        value={display.value}
                                        isUOM={display.isUOM}
                                        metricUnits={display.metricUnits}
                                        imperialUnits={display.imperialUnits}
                                        baseUnits={display.baseUnits}
                                        uomPreference={uomPreference}
                                        removeUnitsIfDefault={display.removeUnitsIfDefault}
                                        allowTruncate={display.allowTruncate}
                                        precision={display.precision}
                                        defaultDisplayValue={display.defaultDisplayValue}
                                    />
                                </ReportTable.Cell>
                            ))}
                        </ReportTable.Row>
                    }

                    return null
                })}
            </ReportTable.Body>
        </ReportTable>
    )
}


function ProductsTable({ ingredients, product, uomPreference, hideNullValues }) {
    const { palette } = useDesignTheme()

    const styles = {
        headerRow: {
            color: palette.primary.main, fontSize: '0.875rem', textAlign: 'center', fontWeight: 'normal',
        },
        bodyRow: {
            color: palette.primary.main, fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center',
        },
    }

    const rows = ingredients?.length ? ingredients : [{
        rowName: product.name,
        rate: product.appRate,
        supplier: {value: DEFAULT_DISPLAY_VALUE},
        totalProduct: product.totalProduct,
    }]

    const shouldShowSupplier = !hideNullValues || rows.some(row => !!row.supplier && row.supplier.value !== DEFAULT_DISPLAY_VALUE)

    return (
        <Stack direction="column" sx={{ pt: 2 }}>
            <Box
                display="flex"
                alignItems="center"
                className="report-section-header"
                sx={{
                    py: '5px',
                    px: '5px',
                    backgroundColor: 'primary.main',
                    fontWeight: 'fontWeightBold',
                    color: 'primary.contrastText',
                }}
            >
                {ingredients?.length > 1 ? 'Products' : 'Product'}
            </Box>
            <Box sx={{ py: 1 / 2, borderBottom: `1px solid ${palette.brandBackground.main}` }}>
                <ReportTable shadeRows>
                    <ReportTable.Head sx={{ backgroundColor: 'inherit' }}>
                        <ReportTable.Row>
                            <ReportTable.Cell sx={styles.headerRow}>{'Name:'}</ReportTable.Cell>
                            <ReportTable.Cell sx={styles.headerRow}>{product.targetTotalProduct.item}:</ReportTable.Cell>
                            <ReportTable.Cell sx={styles.headerRow}>{product.totalActualApplied.item}:</ReportTable.Cell>
                            <ReportTable.Cell sx={styles.headerRow}>{product.appliedArea.item}:</ReportTable.Cell>
                            <ReportTable.Cell sx={styles.headerRow}>{product.appRate.item}</ReportTable.Cell>
                        </ReportTable.Row>
                    </ReportTable.Head>
                    <ReportTable.Body>
                        <ReportTable.Row sx={{ backgroundColor: 'inherit!important' }}>
                            <ReportTable.Cell sx={styles.bodyRow}><DisplayValue value={product.name} preserveStringCase={true}/></ReportTable.Cell>
                            <ReportTable.Cell sx={styles.bodyRow}>
                                <DisplayValue
                                    value={product.targetTotalProduct.value}
                                    metricUnits={product.targetTotalProduct.metricUnits}
                                    imperialUnits={product.targetTotalProduct.imperialUnits}
                                    baseUnits={product.targetTotalProduct.baseUnits}
                                    isUOM={product.targetTotalProduct.isUOM}
                                    precision={product.targetTotalProduct.precision}
                                    uomPreference={uomPreference}
                                    removeUnitsIfDefault={product.targetTotalProduct.removeUnitsIfDefault}
                                    defaultDisplayValue={product.targetTotalProduct.defaultDisplayValue}
                                    allowTruncate={product.targetTotalProduct.allowTruncate}
                                />
                            </ReportTable.Cell>
                            <ReportTable.Cell sx={styles.bodyRow}>
                                <DisplayValue
                                    value={product.totalActualApplied.value}
                                    metricUnits={product.totalActualApplied.metricUnits}
                                    imperialUnits={product.totalActualApplied.imperialUnits}
                                    baseUnits={product.totalActualApplied.baseUnits}
                                    isUOM={product.totalActualApplied.isUOM}
                                    precision={product.totalActualApplied.precision}
                                    uomPreference={uomPreference}
                                    removeUnitsIfDefault={product.totalActualApplied.removeUnitsIfDefault}
                                    defaultDisplayValue={product.totalActualApplied.defaultDisplayValue}
                                    allowTruncate={product.totalActualApplied.allowTruncate}
                                />
                            </ReportTable.Cell>
                            <ReportTable.Cell sx={styles.bodyRow}>
                                <DisplayValue
                                    value={product.appliedArea.value}
                                    metricUnits={product.appliedArea.metricUnits}
                                    imperialUnits={product.appliedArea.imperialUnits}
                                    baseUnits={product.appliedArea.baseUnits}
                                    isUOM={product.appliedArea.isUOM}
                                    precision={product.appliedArea.precision}
                                    uomPreference={uomPreference}
                                    removeUnitsIfDefault={product.appliedArea.removeUnitsIfDefault}
                                    defaultDisplayValue={product.appliedArea.defaultDisplayValue}
                                    allowTruncate={product.appliedArea.allowTruncate}
                                />
                            </ReportTable.Cell>
                            <ReportTable.Cell sx={styles.bodyRow}>
                                <DisplayValue
                                    value={product.appRate.value}
                                    metricUnits={product.appRate.metricUnits}
                                    imperialUnits={product.appRate.imperialUnits}
                                    baseUnits={product.appRate.baseUnits}
                                    isUOM={product.appRate.isUOM}
                                    precision={product.appRate.precision}
                                    uomPreference={uomPreference}
                                    removeUnitsIfDefault={product.appRate.removeUnitsIfDefault}
                                    defaultDisplayValue={product.appRate.defaultDisplayValue}
                                    allowTruncate={product.appRate.allowTruncate}
                                />
                            </ReportTable.Cell>
                        </ReportTable.Row>
                    </ReportTable.Body>
                </ReportTable>
            </Box>
            <ReportTable shadeRows>
                <ReportTable.Head sx={{ backgroundColor: 'inherit' }}>
                    <ReportTable.Row>
                        <ReportTable.Cell sx={{ fontWeight: 'fontWeightBold', color: palette.primary.main, fontSize: '0.875rem' }}>{ingredients?.length > 1 ? 'Products' : 'Product' }</ReportTable.Cell>
                        <ReportTable.Cell sx={{ fontWeight: 'fontWeightBold', color: palette.primary.main }}>Rate</ReportTable.Cell>
                        {shouldShowSupplier && <ReportTable.Cell sx={{ fontWeight: 'fontWeightBold', color: palette.primary.main }}>Supplier</ReportTable.Cell>}
                        <ReportTable.Cell sx={{ fontWeight: 'fontWeightBold', color: palette.primary.main }}>Total Product</ReportTable.Cell>
                    </ReportTable.Row>
                </ReportTable.Head>
                <ReportTable.Body>
                    {rows.map((row, index) => (
                        <ReportTable.Row key={index}>
                            <ReportTable.Cell sx={{ fontWeight: 'fontWeightBold' }} >
                                {row.rowName}
                            </ReportTable.Cell>
                            <ReportTable.Cell>
                                <DisplayValue
                                    value={row.rate.value}
                                    metricUnits={row.rate.metricUnits}
                                    imperialUnits={row.rate.imperialUnits}
                                    baseUnits={row.rate.baseUnits}
                                    isUOM={row.rate.isUOM}
                                    uomPreference={uomPreference}
                                    removeUnitsIfDefault={row.rate.removeUnitsIfDefault}
                                    defaultDisplayValue={row.rate.defaultDisplayValue}
                                    allowTruncate={row.rate.allowTruncate}
                                    precision={row.rate.precision}
                                />
                            </ReportTable.Cell>
                            {shouldShowSupplier && <ReportTable.Cell>
                                <DisplayValue value={row.supplier.value}/>
                            </ReportTable.Cell>}
                            <ReportTable.Cell>
                                <DisplayValue
                                    value={row.totalProduct.value}
                                    metricUnits={row.totalProduct.metricUnits}
                                    imperialUnits={row.totalProduct.imperialUnits}
                                    baseUnits={row.totalProduct.baseUnits}
                                    isUOM={row.totalProduct.isUOM}
                                    uomPreference={uomPreference}
                                    removeUnitsIfDefault={row.totalProduct.removeUnitsIfDefault}
                                    defaultDisplayValue={row.totalProduct.defaultDisplayValue}
                                    allowTruncate={row.totalProduct.allowTruncate}
                                    precision={row.totalProduct.precision}
                                />
                            </ReportTable.Cell>
                        </ReportTable.Row>
                    ))}
                    {rows.length === 0 && (
                        <ReportTable.Row>
                            <ReportTable.Cell colSpan={4}>
                                <DisplayValue value="No Data" />
                            </ReportTable.Cell>
                        </ReportTable.Row>
                    )}
                </ReportTable.Body>
            </ReportTable>
        </Stack>
    )
}

// The only way to get a footer on every page and not have to
// worry about the content overflowing into the footer is to
// wrap everything in a table and use display: table-footer-group on the tfoot
// so that's what this mess is for
function TableWrapper({ children }) {
    return (
        <table>
            <tbody>
                <tr>
                    <td>
                        {children}
                    </td>
                </tr>
            </tbody>
            <tfoot style={{ display: 'table-footer-group', height: '30px' }}>
                <tr>
                    <td>
                        <div className="report-footer-printview">
                            <div className="slingshot-copyright">Copyright {new Date().getFullYear()} Slingshot&reg; All Rights Reserved</div>
                            <img className="powered-by-raven-img" src={`${process.env.PUBLIC_URL}/images/Powered_By_Raven.png`} alt="Powered By Raven" />
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}
