import {DEFAULT_DISPLAY_VALUE } from './applicationReportConstants'
import { appDetailDataBigScreen, appDetailDataSmallScreen, fieldInformationData, headerData } from './reportFunctions'

export function mapFieldAnalyzerApiData(apiData, uomPreference) {
    return mapSessionData()

    function mapSessionData() {
        const products = []
        apiData.fieldAnalyzerCoverage.forEach((element) => {
            products.push(element.fieldAnalyzerProduct.name)
        })

        apiData.sessions.sort((a, b) => new Date(a.startTime) - new Date(b.startTime))

        const sessionIndexes = {}
        apiData.sessions.forEach((element, index) => {
            if (sessionIndexes[element.product.name]) {
                sessionIndexes[element.product.name].push(index)
            }
            else {
                sessionIndexes[element.product.name] = [index]
            }
        })

        const doneProducts = []
        const allPageData = []
        products.forEach((product, index) => {
            if (!doneProducts.includes(product)) {
                const sessionsProduct = sessionsProductData(apiData, sessionIndexes[product])
                const productData = {
                    headerInfo: headerData(apiData, product),
                    subheaderInfo: subheaderData(apiData, index, sessionsProduct),
                    fieldInformation: fieldInformationData(apiData),
                    mapData: mapData(apiData, sessionIndexes[product], index),
                    applicationDetail: appDetailDataBigScreen(apiData, sessionIndexes[product], uomPreference),
                    applicationDetailSmallScreen: appDetailDataSmallScreen(apiData, sessionIndexes[product], uomPreference),
                    productInfo: productInfoData(apiData, index, sessionsProduct),
                    ingredientInfo: ingredientData(apiData, index),
                }
                doneProducts.push(product)
                allPageData.push(productData)
            }
        })

        return allPageData
    }

    function sessionsProductData(apiData, sessionIndexes) {
        let completedArea = 0
        let totalVolume = 0
        let totalWeight = 0
        let appVol = 0
        let applicationVolumeWeight = {}
        let totalProduct = {}
        let totalSavings = 0
        let maxTargetAmount = 0
        let targetTotalProduct = {}
        let percentSavings = 0
        for (let i = 0; i < sessionIndexes.length; i++) {
            const totalArea = apiData.sessions[sessionIndexes[i]].totalArea
            completedArea += totalArea
            totalVolume += apiData.sessions[sessionIndexes[i]].product.totalVolume
            totalWeight += apiData.sessions[sessionIndexes[i]].product.totalWeight
            totalSavings += (totalArea * apiData.fieldAnalyzerSessions[sessionIndexes[i]].maximumRate) - (apiData.sessions[sessionIndexes[i]].product.totalVolume ? apiData.sessions[sessionIndexes[i]].product.totalVolume : apiData.sessions[sessionIndexes[i]].product.totalWeight)
            maxTargetAmount += totalArea * apiData.fieldAnalyzerSessions[sessionIndexes[i]].maximumRate
        }
        percentSavings = totalSavings / maxTargetAmount * 100

        if (totalVolume && completedArea) {
            appVol = totalVolume / completedArea
            applicationVolumeWeight = {
                item: 'Avg. App Rate',
                value: appVol || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'L/ha',
                imperialUnits: 'gal/ac',
                baseUnits: 'L/m2',
                precision: 2,
                allowTruncate: false,
            }
            totalProduct = {
                item: 'Total Product',
                value: totalVolume || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'L',
                imperialUnits: 'gal',
                baseUnits: 'L',
                precision: 2,
                removeUnitsIfDefault: true,
                allowTruncate: false,
            }
            targetTotalProduct = {
                item: 'Maximum Target Volume',
                value: maxTargetAmount || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'L',
                imperialUnits: 'gal',
                baseUnits: 'L',
                precision: 2,
                removeUnitsIfDefault: true,
                defaultDisplayValue: '0',
                allowTruncate: false,
            }
        }

        if (totalWeight && completedArea) {
            appVol = totalWeight / completedArea
            applicationVolumeWeight = {
                item: 'Avg. App Rate',
                value: appVol || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'kg/ha',
                imperialUnits: 'lb/ac',
                baseUnits: 'kg/m2',
                precision: 2,
                removeUnitsIfDefault: true,
                allowTruncate: false,
            }
            totalProduct = {
                item: 'Total Product',
                value: totalWeight || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'kg',
                imperialUnits: 'lb',
                baseUnits: 'kg',
                precision: 2,
                removeUnitsIfDefault: true,
                allowTruncate: false,
            }
            targetTotalProduct = {
                item: 'Maximum Target Volume',
                value: maxTargetAmount || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'kg',
                imperialUnits: 'lb',
                baseUnits: 'kg',
                precision: 2,
                removeUnitsIfDefault: true,
                defaultDisplayValue: '0',
                allowTruncate: false,
            }
        }

        const name = apiData.sessions[sessionIndexes[0]].product.name || DEFAULT_DISPLAY_VALUE

        return {
            applicationVolumeWeight: applicationVolumeWeight,
            totalProduct: totalProduct,
            totalVolume: totalVolume,
            totalWeight: totalWeight,
            completedArea: completedArea,
            name: name,
            totalSavings: totalSavings,
            percentSavings: percentSavings,
            targetTotalProduct: targetTotalProduct,
        }
    }

    function subheaderData(apiData, coverageMapIndex, sessionsProduct) {
        // NOTE: array order affects order on page
        const subheaderInfo = []
        const isSelectiveSpray = apiData.fieldAnalyzerCoverage[coverageMapIndex]?.fieldAnalyzerSettings?.rateProvider?.toUpperCase().indexOf('SELECTIVE SPRAY') >= 0
        const totalSavings = sessionsProduct.totalSavings > 0 ? sessionsProduct.totalSavings : 0
        const percentSavings = sessionsProduct.percentSavings > 0 ? sessionsProduct.percentSavings.toFixed(0) + '%' : '0%'

        let modeOfOperation = ''
        if (apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerSettings.rateProvider && apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerSettings.operationType) {
            modeOfOperation = apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerSettings.rateProvider + ' - ' + apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerSettings.operationType
        }
        else if (apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerSettings.rateProvider) {
            modeOfOperation = apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerSettings.rateProvider
        }
        else if (apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerSettings.operationType) {
            modeOfOperation = apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerSettings.operationType
        }
        else {
            modeOfOperation = DEFAULT_DISPLAY_VALUE
        }


        if (sessionsProduct.totalVolume && sessionsProduct.completedArea) {
            subheaderInfo.push({
                item: 'Saved Volume',
                value: totalSavings,
                isUOM: true,
                metricUnits: 'L',
                imperialUnits: 'gal',
                baseUnits: 'L',
                precision: 2,
                removeUnitsIfDefault: true,
                colSpan: 2,
                textAlign: 'right',
                defaultDisplayValue: '0',
                allowTruncate: false,
            })
        }
        else if (sessionsProduct.totalWeight && sessionsProduct.completedArea) {
            subheaderInfo.push({
                item: 'Saved Volume',
                value: totalSavings,
                isUOM: true,
                metricUnits: 'kg',
                imperialUnits: 'lb',
                baseUnits: 'kg',
                precision: 2,
                removeUnitsIfDefault: true,
                colSpan: 2,
                textAlign: 'right',
                defaultDisplayValue: '0',
                allowTruncate: false,
            })
        }

        subheaderInfo.push({
            value: percentSavings,
            isUOM: false,
            textAlign: 'left',
        })
        subheaderInfo.push({
            item: 'Mode of Operation',
            value: modeOfOperation,
            isUOM: false,
            preserveStringCase: true,
        })
        subheaderInfo.push({
            item: 'Crop Type',
            value: apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerSettings.cropType || DEFAULT_DISPLAY_VALUE,
            isUOM: false,
        })
        subheaderInfo.push({
            item: 'Crop/Stubble Height',
            value: apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerSettings.cropHeight,
            metricUnits: 'cm',
            imperialUnits: 'in',
            isUOM: true,
            precision: 2,
            removeUnitsIfDefault: true,
            allowTruncate: false,
        })
        if (isSelectiveSpray) {
            subheaderInfo.push({
                item: 'Selective Spray Area',
                value: apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerProduct.totalAreaApplied,
                isUOM: true,
                metricUnits: 'ha',
                imperialUnits: 'ac',
                precision: 2,
                removeUnitsIfDefault: true,
                colSpan: 2,
                textAlign: 'right',
                allowTruncate: false,
            })
            subheaderInfo.push({
                value: apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerProduct.percentAreaCovered.toFixed(0) + '%',
                isUOM: false,
                textAlign: 'left',
            })
        }
        if (sessionsProduct.applicationVolumeWeight) {
            subheaderInfo.push(sessionsProduct.applicationVolumeWeight)
        }
        subheaderInfo.push({
            item: 'Field Area',
            value: apiData.field.area ? Number(apiData.field.area) : DEFAULT_DISPLAY_VALUE,
            isUOM: true,
            metricUnits: 'ha',
            imperialUnits: 'ac',
            precision: 2,
            removeUnitsIfDefault: true,
            allowTruncate: false,
            defaultDisplayValue: 'n/a',
        })
        subheaderInfo.push({
            item: 'Covered Area',
            value: sessionsProduct.completedArea || DEFAULT_DISPLAY_VALUE,
            isUOM: true,
            metricUnits: 'ha',
            imperialUnits: 'ac',
            precision: 2,
            removeUnitsIfDefault: true,
            allowTruncate: false,
        })

        return subheaderInfo
    }

    function mapData(apiData, sessionIndexes, coverageMapIndex) {
        const mapData = {
            imageData: apiData.fieldAnalyzerCoverage[coverageMapIndex].imageData,
            features: apiData.fieldAnalyzerCoverage[coverageMapIndex].geoFeatures,
            isLiquid: (apiData.sessions[sessionIndexes[0]].product.liquid === '1'),
        }

        return mapData
    }

    function productInfoData(apiData, coverageMapIndex, sessionsProduct) {
        const productData = {
            name: sessionsProduct.name,
            appRate: sessionsProduct.applicationVolumeWeight,
            totalProduct: sessionsProduct.totalProduct,
            appliedArea: {item: 'Covered Area', value: sessionsProduct.completedArea || DEFAULT_DISPLAY_VALUE, isUOM: true, metricUnits: 'ha', imperialUnits: 'ac', precision: 2, defaultDisplayValue: '0', allowTruncate: false},
            totalActualApplied: {item: 'Total Applied Volume', value: (sessionsProduct.totalWeight ? sessionsProduct.totalWeight : sessionsProduct.totalVolume) || DEFAULT_DISPLAY_VALUE, isUOM: true, baseUnits: 'L', metricUnits: 'L', imperialUnits: 'gal', precision: 2, defaultDisplayValue: '0', allowTruncate: false},
            targetTotalProduct: sessionsProduct.targetTotalProduct,
        }

        return productData
    }

    function ingredientData(apiData, coverageMapIndex) {
        const ingredients = []

        for (let j = 0; j < apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerProduct.ingredients.length; j++) {
            const ingred = {
                name: apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerProduct.ingredients[j].name,
                rate: Number(apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerProduct.ingredients[j].rate),
                company: apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerProduct.ingredients[j].company,
                quantity: Number(apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerProduct.ingredients[j].quantity),
                liquid: Number(apiData.fieldAnalyzerCoverage[coverageMapIndex].fieldAnalyzerProduct.ingredients[j].liquid),
            }

            ingredients.push(ingred)
        }

        const ingredientRows = []
        for (let i = 0; i < ingredients.length; i++) {
            const metricUnitsRate = ingredients[i].liquid ? 'L/ha' : 'kg/ha'
            const imperialUnitsRate = ingredients[i].liquid ? 'gal/ac' : 'lb/ac'
            const baseUnitsRate = ingredients[i].liquid ? 'L/m2' : 'kg/m2'

            const metricUnitsQuantity = ingredients[i].liquid ? 'L' : 'kg'
            const imperialUnitsQuantity = ingredients[i].liquid ? 'gal' : 'lb'
            const baseUnitsQuantity = ingredients[i].liquid ? 'L' : 'kg'

            ingredientRows.push({
                rowName: ingredients[i].name || DEFAULT_DISPLAY_VALUE,
                rate: {value: Number(ingredients[i].rate) || DEFAULT_DISPLAY_VALUE, isUOM: true, precision: 6, metricUnits: metricUnitsRate, imperialUnits: imperialUnitsRate, baseUnits: baseUnitsRate, removeUnitsIfDefault: true, defaultDisplayValue: '0', allowTruncate: false},
                supplier: {value: ingredients[i].company || DEFAULT_DISPLAY_VALUE},
                totalProduct: {value: Number(ingredients[i].quantity) || DEFAULT_DISPLAY_VALUE, isUOM: true, precision: 4, metricUnits: metricUnitsQuantity, imperialUnits: imperialUnitsQuantity, baseUnits: baseUnitsQuantity, removeUnitsIfDefault: true, defaultDisplayValue: '0', allowTruncate: false},
            })
        }

        const ingredientData = {
            ingredientRows: ingredientRows,
        }

        return ingredientData
    }
}
