import {errorCatch, errorCheck} from '../lib/actionErrorHandler'

import axios from '../lib/axios'

export function setRxMapEditDialog(rxMapEditDialog) {
    return {
        type: 'SET_RX_MAP_EDIT_DIALOG',
        rxMapEditDialog,
    }
}

function requestRxMaps() {
    return {
        type: 'REQUEST_RX_MAPS',
    }
}

function requestRxMapMeta() {
    return {
        type: 'REQUEST_RX_MAP_META',
    }
}

function receiveRxMaps(json) {
    if (!Array.isArray(json)) { json = [] }

    return {
        type: 'RECEIVE_RX_MAPS',
        rxFiles: json,
    }
}

function appendRxMaps(json) {
    if (!Array.isArray(json)) { json = [] }

    return {
        type: 'APPEND_RXMAPS',
        rxFiles: json,
    }
}


function receiveRxMapMeta(meta) {
    if (!Array.isArray(meta.boundingBox)) { meta.boundingBox = [] }
    if (!Array.isArray(meta.columns)) { meta.columns = [] }

    return {
        type: 'RECEIVE_RX_MAP_META',
        rxFiles: meta,
    }
}

export function fetchRxMaps() {
    return dispatch => {
        dispatch(requestRxMaps)

        return axios.get('/jobs/api/rxmaps?pageSize=500&page=1')
            .then(response => {
                dispatch(receiveRxMaps(response.data.data))

                return response.data.pagination.next
            })
            .then(next => paginateRxMaps(next, dispatch))

            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveRxMaps([]))
            })
    }
}

function paginateRxMaps(next, dispatch) {
    if (next) {
        return axios.get(`/jobs/api/rxmaps?cursor=${next}`)
            .then(response => {
                dispatch(appendRxMaps(response.data.data))

                return response.data.pagination.next
            })
            .then(next => paginateRxMaps(next, dispatch))
    }

    return dispatch({type: 'DONE_FETCHING_RXMAPS'})
}

export function fetchRxMapMeta(id) {
    return dispatch => {
        dispatch(requestRxMapMeta)

        return axios.get(`/jobs/api/rxmaps/${id}/meta`)
            .then(response => response.data)
            .then(json => dispatch(receiveRxMapMeta(Object.assign({id: id}, json))))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return null
            })
    }
}

export function sendRxMapFile(fileData) {
    return dispatch => {
        dispatch(uploadingRxMap())
        const headers = {
            'Content-Type': 'multipart/form-data',
        }

        return axios.post('/jobs/api/rxmaps', fileData, {
            headers,
        }).then(response => response.data)
            .then(resp => {
                dispatch(uploadingRxMapComplete())

                return resp
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return null
            })
    }
}

export function uploadingRxMap() {
    return {
        type: 'UPLOADING_RX_MAP',
    }
}

export function uploadingRxMapComplete() {
    return {
        type: 'UPLOADING_RX_MAP_COMPLETE',
    }
}
