import { combineReducers } from 'redux'
import update from 'immutability-helper'

export function rxMapEditDialog(state = [], action) {
    switch (action.type) {
        case 'SET_RX_MAP_EDIT_DIALOG':
            return update(state, {
                $set: action.rxMapEditDialog,
            })
        default:
            return state
    }
}

export function rxFiles(state = [], action) {
    let index
    switch (action.type) {
        case 'RECEIVE_RX_MAPS':
            return update(state, {
                $set: action.rxFiles,
            })
        case 'RECEIVE_RX_MAP_META':
            if (!action.rxFiles || !action.rxFiles.id) { return state }
            index = state.findIndex(map => map.id === action.rxFiles.id)

            return update(state, {
                [index]: {
                    $merge: action.rxFiles,
                },
            })
        case 'APPEND_RX_MAPS':
            return update(state, {
                data: { $push: action.rxFiles },
            })
        case 'DONE_FETCHING_RX_MAPS':
            return update(state, {
                isLoading: { $set: false },
            })
        default:
            return state
    }
}

export const defaultRxFile = {
    rxFiles: [],
    rxMapEditDialog: { open: false, error: null },
}
const rxFileReducer = combineReducers({
    rxFiles,
    rxMapEditDialog,
})

export default rxFileReducer
