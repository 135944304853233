import { defaultRxFile, rxFiles, rxMapEditDialog } from './rxmapReducer'

// a reducer takes in two things:
// 1. the action (info about what happened)
// 2. a copy of the current state
import { combineReducers } from 'redux'
import { productPrototype } from './productPrototypes'
import update from 'immutability-helper'

function mixes(state = {}, action) {
    switch (action.type) {
        case 'REQUEST_MIXES':
            return update(state, {
                isLoading: { $set: true },
            })
        case 'RECEIVE_MIXES':
            return update(state, {
                data: { $set: action.mixes },
                isLoading: { $set: false },
            })
        case 'APPEND_MIXES':
            return update(state, {
                data: { $push: action.mixes },
            })
        case 'DONE_FETCHING_MIXES':
            return update(state, {
                isLoading: { $set: false },
            })
        case 'ERROR_MIXES':
            return update(state, {
                isLoading: { $set: false },
                error: { $set: action.error },
            })
        default:
            return state
    }
}

function ingredients(state = [], action) {
    switch (action.type) {
        case 'REQUEST_INGREDIENTS':
            return update(state, {
                isLoading: { $set: true },
            })
        case 'RECEIVE_INGREDIENTS':
            return update(state, {
                data: { $set: action.ingredients },
                isLoading: { $set: false },
            })
        case 'APPEND_INGREDIENTS':
            return update(state, {
                data: { $push: action.ingredients },
            })
        case 'DONE_FETCHING_INGREDIENTS':
            return update(state, {
                isLoading: { $set: false },
            })
        case 'ERROR_INGREDIENTS':
            return update(state, {
                isLoading: { $set: false },
                error: { $set: action.error },
            })

        default:
            return state
    }
}

function carriers(state = [], action) {
    switch (action.type) {
        case 'RECEIVE_CARRIERS':
            return update(state, {
                $set: action.carriers,
            })
        case 'APPEND_CARRIERS':
            return update(state, {
                data: { $push: action.carriers },
            })
        case 'DONE_FETCHING_CARRIERS':
            return update(state, {
                isLoading: { $set: false },
            })

        default:
            return state
    }
}

function currentProducts(state = [], action) {
    switch (action.type) {
        case 'SET_CURRENT_PRODUCTS':
            return update(state, {
                $set: action.currentProducts,
            })
        default:
            return state
    }
}

export function selectMixes(state) {
    return state.product.mixes.data || []
}

export function selectIngredients(state) {
    return state.product.ingredients.data || []
}

export const defaultProduts = {
    carriers: [],
    ingredients: {
        isLoading: true,
        error: null,
        data: [],
    },
    mixes: {
        isLoading: true,
        error: null,
        data: [],
    },
    currentProducts: [new productPrototype()],
    ...defaultRxFile,
}
const productReducer = combineReducers({
    carriers,
    ingredients,
    mixes,
    currentProducts,
    rxFiles,
    rxMapEditDialog,
})

export default productReducer
