import {errorCatch, errorCheck} from '../lib/actionErrorHandler'

import axios from '../lib/axios'

export function fetchIngredients() {
    return dispatch => {
        dispatch(requestIngredients())

        return axios.get('/jobs/api/ingredients?pageSize=500&page=1')
            .then(response => {
                dispatch(receiveIngredients(response.data.data))

                return response.data.pagination.next
            })
            .then(next => paginateIngredients(next, dispatch))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(errorIngredients('Error retrieving ingredients'))
            })
    }
}

function paginateIngredients(next, dispatch) {
    if (next) {
        return axios.get(`/jobs/api/ingredients?cursor=${next}`)
            .then(response => {
                dispatch(appendIngredients(response.data.data))

                return response.data.pagination.next
            })
            .then(next => paginateIngredients(next, dispatch))
    }

    return dispatch({type: 'DONE_FETCHING_INGREDIENTS'})
}

export function addIngredient(ingredient) {
    return dispatch => {
        return axios.post('/jobs/api/ingredients/', ingredient)
            .then(response => response.data)
            .then((json) => {
                dispatch(fetchIngredients())

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return null
            })
    }
}

export function updateIngredient(ingredient) {
    return dispatch => {
        return axios.put(`/jobs/api/ingredients/${ingredient.id}`, ingredient)
            .then(response => response.data)
            .then((json) => {
                dispatch(fetchIngredients())

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return null
            })
    }
}

export function deleteIngredients(ingredientIds) {
    return dispatch => {
        const params = {
            data: {ingredientIds},
        }

        return axios.delete('/jobs/api/ingredients/', params)
            .then(response => response.data)
            .then(json => {
                dispatch(fetchIngredients())

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return null
            })
    }
}

function requestIngredients() {
    return {
        type: 'REQUEST_INGREDIENTS',
    }
}

function receiveIngredients(ingredients) {
    if (!Array.isArray(ingredients)) { ingredients = [] }

    return {
        type: 'RECEIVE_INGREDIENTS',
        ingredients: ingredients,
    }
}

function appendIngredients(ingredients) {
    if (!Array.isArray(ingredients)) { ingredients = [] }

    return {
        type: 'APPEND_INGREDIENTS',
        ingredients: ingredients,
    }
}

export function fetchCarriers() {
    return dispatch => {
        dispatch(requestCarriers())

        return axios.get('/jobs/api/carriers?pageSize=500&page=1')
            .then(response => {
                dispatch(receiveCarriers(response.data.data))

                return response.data.pagination.next
            })
            .then(next => paginateCarriers(next, dispatch))

            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveCarriers([]))
            })
    }
}

function paginateCarriers(next, dispatch) {
    if (next) {
        return axios.get(`/jobs/api/carriers?cursor=${next}`)
            .then(response => {
                dispatch(appendCarriers(response.data.data))

                return response.data.pagination.next
            })
            .then(next => paginateCarriers(next, dispatch))
    }

    return dispatch({type: 'DONE_FETCHING_CARRIERS'})
}

function requestCarriers() {
    return {
        type: 'REQUEST_CARRIERS',
    }
}

function receiveCarriers(carriers) {
    if (!Array.isArray(carriers)) { carriers = [] }

    return {
        type: 'RECEIVE_CARRIERS',
        carriers: carriers,
    }
}

function appendCarriers(carriers) {
    if (!Array.isArray(carriers)) { carriers = [] }

    return {
        type: 'APPEND_CARRIERS',
        carriers: carriers,
    }
}

function errorIngredients(error) {
    return {
        type: 'ERROR_INGREDIENTS',
        error,
    }
}
